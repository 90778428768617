import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import AlertContext from '../../../Alert/Context';
const AuftragsdetailsQuery = loader('../../AuftragsdetailsQuery.graphql');
const VerbindeUevMitAuevMutation = loader(
  './VerbindeUevMitAuevMutation.graphql'
);

export default ({ auftragsnummer }) => {
  const [state, setState] = useState({
    auftragsnummer: '',
  });

  const { showAlert } = useContext(AlertContext);

  const [verbindeUevMitAuev] = useMutation(VerbindeUevMitAuevMutation, {
    update(cache, { data: { verbindeUevMitAuev } }) {
      cache.writeQuery({
        query: AuftragsdetailsQuery,
        variables: {
          auftragsnummer: parseInt(auftragsnummer),
        },
        data: { auftragsdetails: verbindeUevMitAuev },
      });
    },
  });

  const handleChange = (event) => {
    setState({
      auftragsnummer: event.target.value,
    });
  };

  const isValid = !!state.auftragsnummer && state.auftragsnummer.length === 5;
  const showError = !isValid && state.auftragsnummer.length > 4;

  const resetForm = () => {
    setState({
      auftragsnummer: '',
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    verbindeUevMitAuev({
      variables: {
        uevAuftragsnummer: parseInt(auftragsnummer),
        auftragsnummer: state.auftragsnummer,
      },
    })
      .then(({ data }) => {
        const neueAuevAuftragsnummer =
          data.verbindeUevMitAuev.rahmenvertrag.auev.auftragsnummer;

        showAlert({
          message: `Dieser Auftrag verweist nun auf die Staffing-AÜV aus Auftrag ${neueAuevAuftragsnummer}.`,
          type: 'success',
        });
      })
      .catch(({ error }) => {
        showAlert({
          message: `Keine Staffing-AÜV zu Auftrag ${state.auftragsnummer} gefunden.`,
          type: 'danger',
        });
      })
      .finally(resetForm);
  };

  return (
    <form className="w-100" onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="temping_verweis_form_auftragsnummer">
          Auftragsnummer
        </label>
        <input
          id="temping_verweis_form_auftragsnummer"
          type="number"
          className={`form-control ${showError ? 'is-invalid' : ''}`}
          value={state.auftragsnummer}
          onChange={handleChange}
          min="10000"
          max="99999"
          required
        />
        {showError ? (
          <div className="invalid-feedback mt-2">
            Bitte gib eine fünfstellige Nummer ein.
          </div>
        ) : null}
      </div>
      <button
        type="submit"
        className="btn btn-primary w-auto"
        disabled={!isValid}
      >
        Speichern
      </button>
    </form>
  );
};
