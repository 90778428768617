import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import AlertContext from '../../../Alert/Context';
const AuftragsdetailsQuery = loader('../../AuftragsdetailsQuery.graphql');
const VerbindeUevMitYoungProfessionalsVertragMutation = loader(
  './VerbindeUevMitYoungProfessionalsVertragMutation.graphql'
);

export default ({ auftragsnummer }) => {
  const initialVertragsnummer = '';

  const [state, setState] = useState({
    vertragsnummer: initialVertragsnummer,
  });

  const { showAlert } = useContext(AlertContext);

  const [verbindeUevMitYoungProfessionalsVertrag] = useMutation(
    VerbindeUevMitYoungProfessionalsVertragMutation,
    {
      update(cache, { data: { verbindeUevMitYoungProfessionalsVertrag } }) {
        cache.writeQuery({
          query: AuftragsdetailsQuery,
          variables: {
            auftragsnummer: parseInt(auftragsnummer),
          },
          data: { auftragsdetails: verbindeUevMitYoungProfessionalsVertrag },
        });
      },
    }
  );

  const handleChange = (event) => {
    setState({
      vertragsnummer: event.target.value,
    });
  };

  const isValid = !!state.vertragsnummer && state.vertragsnummer.length === 5;
  const showError = !isValid && state.vertragsnummer.length > 4;

  const resetForm = () => {
    setState({
      vertragsnummer: '',
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    verbindeUevMitYoungProfessionalsVertrag({
      variables: {
        uevAuftragsnummer: parseInt(auftragsnummer),
        vertragsnummer: state.vertragsnummer,
      },
    })
      .then(({ data }) => {
        const neueVertragsnummer =
          data.verbindeUevMitYoungProfessionalsVertrag.rahmenvertrag
            .youngProfessionalsVertrag.vertragsnummer;

        showAlert({
          message: `Dieser Auftrag verweist nun auf den Young-Professionals-Vertrag mit der Nummer ${neueVertragsnummer}.`,
          type: 'success',
        });
      })
      .finally(resetForm);
  };

  return (
    <form className="w-100" onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="young_professionals_verweis_form_vertagsnummer">
          Vertragsnummer
        </label>
        <input
          id="young_professionals_verweis_form_vertragsnummer"
          type="number"
          className={`form-control ${showError ? 'is-invalid' : ''}`}
          value={state.vertragsnummer}
          onChange={handleChange}
          min="10000"
          max="99999"
          required
        />
        {showError ? (
          <div className="invalid-feedback mt-2">
            Bitte gib eine fünfstellige Nummer ein.
          </div>
        ) : null}
      </div>
      <button
        type="submit"
        className="btn btn-primary w-auto"
        disabled={!isValid}
      >
        Speichern
      </button>
    </form>
  );
};
