import React, { useState } from 'react';
import Card from '../Card';
import { Link } from 'react-router-dom';
import { formatDate } from '../../lib/Localization';

const byErstelltAmAsc = (element1, element2) => {
  const erstelltAm1 = new window.Date(element1.erstelltAm);
  const erstelltAm2 = new window.Date(element2.erstelltAm);

  if (erstelltAm1 > erstelltAm2) {
    return 1;
  } else if (erstelltAm1 < erstelltAm2) {
    return -1;
  } else {
    return 0;
  }
};

export default (props) => {
  const weitereAuftraege = props.auftragsdetails.weitereAuftraege.slice();
  const limit = 10;

  const [state, setState] = useState({
    expanded: false,
    expandable: weitereAuftraege.length > limit,
  });

  const clickHandler = () => {
    setState({
      expanded: !state.expanded,
      expandable: state.expandable,
    });
  };

  return (
    <Card>
      <h2 className="h2 mb-6">Weitere Aufträge</h2>
      {weitereAuftraege.length > 0 ? (
        <React.Fragment>
          <ul className="list-unstyled">
            {weitereAuftraege
              .sort(byErstelltAmAsc)
              .slice(
                0,
                state.expandable && !state.expanded
                  ? limit
                  : weitereAuftraege.length
              )
              .map((auftrag, index) => (
                <li className="mb-2 row" key={index}>
                  <Link
                    to={`/auftrag/${auftrag.auftragsnummer}`}
                    className="col-4"
                  >
                    {`${auftrag.auftragsnummer}`}
                  </Link>
                  <span className="col-8">{`vom ${formatDate(
                    new window.Date(auftrag.erstelltAm)
                  )}`}</span>
                </li>
              ))}
          </ul>
          {state.expandable ? (
            <button className="btn btn-link p-0" onClick={clickHandler}>
              {state.expanded ? 'weniger anzeigen' : 'mehr anzeigen'}
            </button>
          ) : null}
        </React.Fragment>
      ) : (
        'keine'
      )}
    </Card>
  );
};
