import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { format } from 'date-fns';
import AlertContext from '../../../Alert/Context';
import { formatDate } from '../../../../lib/Localization';
const AuftragsdetailsQuery = loader('../../AuftragsdetailsQuery.graphql');
const VerbindeUevMitFremdvertragMutation = loader(
  './VerbindeUevMitFremdvertragMutation.graphql'
);

export default ({ auftragsnummer }) => {
  const initialVertragsdatum = format(new window.Date(), 'yyyy-MM-dd');
  const [state, setState] = useState({
    vertragsdatum: initialVertragsdatum,
  });

  const { showAlert } = useContext(AlertContext);

  const [verbindeUevMitFremdvertrag] = useMutation(
    VerbindeUevMitFremdvertragMutation,
    {
      update(cache, { data: { verbindeUevMitFremdvertrag } }) {
        cache.writeQuery({
          query: AuftragsdetailsQuery,
          variables: {
            auftragsnummer: parseInt(auftragsnummer),
          },
          data: { auftragsdetails: verbindeUevMitFremdvertrag },
        });
      },
    }
  );

  const handleChange = (event) => {
    setState({
      vertragsdatum: event.target.value,
    });
  };

  const resetForm = () => {
    setState({
      vertragsdatum: initialVertragsdatum,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    verbindeUevMitFremdvertrag({
      variables: {
        uevAuftragsnummer: parseInt(auftragsnummer),
        vertragsdatum: state.vertragsdatum,
      },
    })
      .then(({ data }) => {
        const neuesVertragsdatum =
          data.verbindeUevMitFremdvertrag.rahmenvertrag.fremdvertrag
            .vertragsdatum;
        const formattedVertragsdatum = formatDate(
          new window.Date(neuesVertragsdatum)
        );

        showAlert({
          message: `Dieser Auftrag verweist nun auf einen Fremdvertrag mit dem Vertragsdatum ${formattedVertragsdatum}.`,
          type: 'success',
        });
      })
      .finally(resetForm);
  };

  return (
    <form className="w-100" onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="fremdvertrag_verweis_form_vertragsdatum">
          Vertragsdatum
        </label>
        <input
          id="fremdvertrag_verweis_form_vertragsdatum"
          type="date"
          className="form-control"
          value={state.vertragsdatum}
          onChange={handleChange}
          required
        />
      </div>
      <button
        type="submit"
        className="btn btn-primary w-auto"
        disabled={!state.vertragsdatum}
      >
        Speichern
      </button>
    </form>
  );
};
