import React from 'react';
import { ApolloProvider } from '@apollo/client';
import apolloClient from '../../lib/apolloClient';
import { BrowserRouter as Router } from 'react-router-dom';
import Routing from '../Routing';
import { hot } from 'react-hot-loader';

const App = () => (
  <ApolloProvider client={apolloClient}>
    <Router>
      <Routing />
    </Router>
  </ApolloProvider>
);

export default hot(module)(App);
