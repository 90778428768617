import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/link-error';
import * as ActionCable from 'actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import LegalAPI from '../LegalAPI';
import setContextHandler from './setContextHandler';
import onErrorHandler from './onErrorHandler';

const httpLink = createHttpLink({
  uri: LegalAPI.graphqlEndpoint(),
});

const hasSubscriptionOperation = ({ query: { definitions } }) => {
  return definitions.some(
    ({ kind, operation }) =>
      kind === 'OperationDefinition' && operation === 'subscription'
  );
};

const cable = ActionCable.createConsumer(LegalAPI.actioncableEndpoint());

const link = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({ cable }),
  httpLink
);

const authLink = setContext(setContextHandler);
const errorLink = onError(onErrorHandler);

const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, link]),
  cache: new InMemoryCache(),
});

export default apolloClient;
